h1,
p {
  font-family: Lato;
}
h1,
p {
  font-family: Lato;
}

.card-ordini {
  margin-bottom: 20px;
  margin-top: 20px;
}
.centered {
  margin-top: 20px;
  text-align: center;
  vertical-align: middle;
}

.button-ordine-destra {
  margin: 0 auto;
  display: block;
  width: 100%;
}
.button-ordine-sinistra {
  float: right;
}
.ordine-completato {
  text-align: center;
  vertical-align: middle;
}
.inside-button {
  font-size: 250px;
  color: green;
}
.ordine-urgente {
  background-color: rgba(255, 0, 0, 0.849);
}
.bg-custom {
  background-color: rgba(255, 0, 0, 0.308) !important;
}
.button-traking {
  float: right;
  border: #fff !important;
}
.button-warning {
  float: right;
  margin-right: 6px;
  background-color: rgba(231, 178, 80, 0.897) !important;
  border: #fff !important;
}
.colore-card-spedito{
  background-color: rgba(80, 231, 100, 0.897) !important;
}
.colore-card-inlavorazione{
  background-color: rgba(231, 178, 80, 0.897) !important;
}
.colore-card-ricevuto{
  background-color: rgba(231, 80, 80, 0.897) !important;
}
.button-tracking2 {
  float: right;
  margin-left: 6px;
  background-color: rgba(224, 66, 45, 0.897) !important;
  border: #fff !important;
}
.spinner-offcanvas-traking {
  background:url(../src/ball.gif) center center no-repeat;
}